body {
    @apply text-sm lg:text-[15px] 2xl:text-base text-dark-color font-primary font-normal;
}
#root {
    @apply overflow-x-hidden;
}
*:focus-visible {
    outline: unset;
    border: unset;
}
* {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}
/* time line */
#time-line > div {
    @apply rounded-full relative bg-[#efefef] h-1;
}

#time-line > div::before {
    position: absolute;
    color: #979797;
    transform: translate(-50%, -50%);
    left: 100%;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    @apply w-[30px] h-[30px];
    @apply text-sm;
    @apply bg-[#efefef] border-2 border-[#efefef] z-20 rounded-[50%];
}

#time-line > div::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 99px;
    z-index: 19;
}

#time-line > div.active:before {
    animation-name: timeLine-2;
    animation-duration: 1s;
    @apply border-2 border-primary bg-white text-primary duration-1000;
}

#time-line > div.active.done:before {
    animation-name: done;
    animation-duration: 1s;
    @apply text-white bg-primary;
}

@keyframes done {
    from {
        @apply bg-white text-[#979797];
    }

    to {
        @apply bg-primary text-white;
    }
}

#time-line > div.active:after {
    animation-name: timeLine-1;
    animation-duration: 1s;
    @apply bg-primary;
}

@keyframes timeLine-1 {
    from {
        width: 0;
    }

    to {
        @apply bg-primary w-full;
    }
}

@keyframes timeLine-2 {
    0% {
        @apply border-2 border-[#efefef] bg-[#efefef];
    }

    100% {
        @apply border-2 border-primary bg-white;
    }
}

#time-line > div.finish:before {
    content: '\2713';
    animation-name: finish;
    animation-duration: 1s;
    @apply text-white bg-secondary border-secondary;
}
@keyframes finish {
    from {
        @apply bg-white text-[#979797] border-[#efefef];
    }

    to {
        @apply bg-secondary text-white border-secondary;
    }
}
#time-line > div.finish:after {
    animation-name: timeLine-finish;
    animation-duration: 1s;
    @apply bg-secondary;
}

@keyframes timeLine-finish {
    from {
        width: 0;
    }

    to {
        @apply bg-secondary w-full;
    }
}

.swiper-button-prev {
    @apply !bg-transparent !text-[#484848] after:content-['prev'] lg:!text-[24px] after:!text-lg !top-[55%];
}
.swiper-button-next {
    @apply !bg-transparent !text-[#484848] after:content-['next'] lg:!text-[24px] after:!text-lg !top-[55%];
}

#brand .swiper-button-next::after,
#brand .swiper-button-prev::after {
    font-size: 2.12rem !important;
    color: black;
}

#brand .swiper-button-prev {
    left: 0;
    right: auto;
}
#brand .swiper-button-next {
    right: 0;
    left: auto;
}

#primary-menu .mega-menu {
    @apply invisible duration-500 opacity-0;
}
#primary-menu li:hover .mega-menu {
    @apply visible opacity-100;
}
#primary-menu .customerService-menu {
    @apply invisible duration-500 opacity-0;
}
#primary-menu li:hover .customerService-menu {
    @apply visible opacity-100;
}

p {
    @apply leading-loose !important;
}

#profileMenu:hover > div {
    @apply !visible !opacity-100;
}

::placeholder {
    @apply text-xs md:text-sm 2xl:text-base !text-gray-400/80;
}
#tabAppointment::-webkit-scrollbar {
    height: 1px;
}
button svg {
    @apply pointer-events-none;
}

.Calendar__yearText {
    display: none !important;
}

.Calendar {
    box-shadow: none !important;
    border: 1px solid lightgray;
    @apply !w-full;
}
input.error {
    border-color: red;
}

.input-feedback {
    color: red;
    margin-top: 0.25rem;
}

.animationOpacity {
    animation-name: animation-opacity;
    animation-duration: 1s;
}

/* The animation code */
@keyframes animation-opacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
