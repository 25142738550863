@font-face {
    font-family: 'poppins';
    src: url('../assets/fonts/Poppins-Light.ttf');
    font-weight: 300;
}

@font-face {
    font-family: 'poppins';
    src: url('../assets/fonts/Poppins-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'poppins';
    src: url('../assets/fonts/Poppins-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'poppins';
    src: url('../assets/fonts/Poppins-SemiBold.ttf');
    font-weight: 600;
}
@font-face {
    font-family: 'poppins';
    src: url('../assets/fonts/Poppins-Bold.ttf');
    font-weight: 700;
}
@font-face {
    font-family: 'poppins';
    src: url('../assets/fonts/Poppins-Black.ttf');
    font-weight: 900;
}

@font-face {
    font-family: 'salsa';
    src: url('../assets/fonts/Salsa-Regular.ttf');
    font-weight: 400;
}
