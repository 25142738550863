:root {
    --parimary: '#197BFF'; /*blue color*/
    --secondary: '#03B404'; /*green color*/
    --gary-light: '#F4F4FF'; /*gray color for background*/

    /* color text */
    --dark-color: '#383838'; /*black color for primary text*/
    --light-color: '#B9B9B9'; /*gray color for secondary text */

    /* shodow default */
    --shadow-primary: '0px 18px 40px rgba(25, 123, 255, 0.4)';
    --shadow-secondary: '0px 18px 40px rgba(3, 180, 4, 0.4)';
    --shadow-box: '0px 18px 40px rgba(16, 84, 139, 0.16)';
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './fonts.css';
@import './custom.css';
